/* eslint-disable */

export default {
  init() {
    const hamburgerButton = document.querySelector(".nav-hamburger");
    const overlayClose = document.querySelector(".nav-links-overlay-close");
    const overlayMenu = document.querySelector(".nav-links-overlay");

    const toggleMenu = () => {
      overlayMenu.classList.toggle("open");
    };

    overlayClose.addEventListener("click", toggleMenu);
    hamburgerButton.addEventListener("click", toggleMenu);
  },
  finalize() {},
};
